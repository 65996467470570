import React, {Component} from 'react'
import './blog.less';
import Wrapper from '../components/Wrapper';
import Letterbox from "../components/Letterbox/index";
import PageTitle from "../components/PageTitle/index";
import {Col, Pagination, Row} from 'antd';
import { navigate } from '@reach/router';
import BlogListItem from "../components/BlogListItem";
import { graphql } from 'gatsby';

class Blog extends Component {

    gotoPage(page){
        if(page===1){
            navigate(`/blog`);
        } else {
            navigate(`/blog/page/${page}`);
        }
    }

    render() {

        const page = this.props.data.wordpressPage;
        const posts = this.props.data.allWordpressPost.edges;
        const acfFields = this.props.data.wordpressPage.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
            >
                <PageTitle
                    title={page.title}
                    image={page.acf.page_title_banner.localFile.childImageSharp.original.src}
                />
                <div className="blog-listing">
                    <Row>
                        <Col xs={{span: 24}} lg={{span: 20, offset: 2}}>
                            {posts && posts.map(({node}) => {
                                return (
                                    <BlogListItem
                                        key={node.id}
                                        post={node}
                                    />
                                )
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span: 24}}>
                            <Pagination
                                current={this.props.pageContext.current}
                                total={this.props.pageContext.numPosts}
                                pageSize={this.props.pageContext.limit}
                                onChange={this.gotoPage}
                            />
                        </Col>
                    </Row>
                </div>
                <Letterbox
                    header={page.acf.letterbox_heading}
                    ctaText={page.acf.letterbox_cta_text}
                    ctaLink={page.acf.letterbox_cta_link}
                />
            </Wrapper>
        )
    }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    wordpressPage(slug: { eq: "blog" }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      title
      acf {
        page_title_banner {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }
        }
        letterbox_heading
        letterbox_cta_text
        letterbox_cta_link
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        } 
      }
    },
    allWordpressPost(limit: $limit, skip: $skip, sort: { order: DESC, fields: [date] }){
      edges {
        node {
          id
          slug
          link
          wordpress_id
          title
          date(formatString: "MMMM YYYY")
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 350, maxHeight:215, quality: 90, cropFocus: CENTER) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }    
            }
          }
        }
      }
    }
  }
`

export default Blog
